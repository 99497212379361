<template>
  <mdb-container class="container">
    <mdb-row center>
      <mdb-col sm="5">
        <mdb-card>
          <mdb-card-body>
            <!-- Material form login -->
            <h5> 개인정보 수집 및 이용 동의서</h5>
            <br/>
            <div class="notice">
              <span>1. 개인정보의 수집 및 이용목적</span>
              <p>개인정보의 수집은 아래와 같은 목적을 위하여 수집합니다. (이외의 목적으로는 사용되지 않습니다.)</p>
              <p>- 아이디어 공모자 신원 확인<br>
                - 상금 및 사은품 지급<br>
                - 아이디어 실현을 위한 추가의견 수집</p>
              <span>2. 수집하는 개인정보의 항목</span>
              <p>- 본 폴리스랩2.0 홈페이지에서는 아이디어 신청, 신청자 관리 등의 서비스를 위해서 최소한의 개인정보를 수집하고 있습니다.<br>
                - 수집항목 : 이름, 이메일주소, 연락처, 경품 수령주소</p>
              <span>3. 개인정보의 보유 및 이용기간</span>
              <p>폴리스랩2.0 홈페이지는 회원의 개인정보를 폴리스랩2.0 사업 기간 동안 제한적으로 이용하고 있습니다.
                회원이 탈퇴를 요청하거나 제공한 개인정보의 이용에 대한 동의를 철회하는 경우,
                해당 사업이 종료된 경우에 관련 개인정보는 지체없이 파기됩니다.</p>
              <span> 4. 개인정보 제공에 동의하지 않을경우 본 사이트 접속 및 아이디어 공모전 이용에 제한이 있을 수 있습니다.</span>
            </div>
            <br/>
            <div class="text-center">
              <router-link to="/user/signUp">
                <mdb-btn>동의</mdb-btn>
              </router-link>
            </div>
            <br>
            <!-- Material form login -->
          </mdb-card-body>
        </mdb-card>

      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>
<script>
import {mdbCard, mdbCardBody, mdbCol, mdbContainer, mdbRow, mdbBtn} from "mdbvue";

export default {
  name: 'Terms',
  components: {
    mdbCardBody,
    mdbCard, mdbContainer, mdbRow, mdbCol, mdbBtn,
  },
}
</script>

<style scoped>
.container {
  margin-top: 150px;
}

.grey-text {
  font-family: AppleSDGothicNeo, serif;
}

.text-center {
  font-family: AppleSDGothicNeo, serif
}

.notice {
  overflow: auto;
  height: 250px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 10px 15px 15px;
  border: solid 1px #ced4da;
  color: #495057;
}

.notice p {
  padding-left: 15px;
}

.notice h5 {
  font-weight: bold;
}

.notice span {
  font-weight: bold;
  display: block;
  padding-bottom: 5px;
}
</style>
